<template>
    <div id="wrap">
        <top page="activity" :showSearchButton="false" />
        <div class="mobile-wrap">
            <div class="module-1">
                <div class="module-1-bg">
                    <img src="../style/img/temp-05.jpg" />
                </div>
                <div class="mainBox">
                    <div class="list">
                        <div class="pic">
                            <img 
                                :src="activity.cover.absolutePath"
                            />
                        </div>
                        <div class="con">
                            <h1>
                                {{activity.name}}
                                <div class="label" v-if="activity.condition.indexOf('VIP') > -1">VIP活动</div>
                            </h1>
                            <div class="list-info">
                                <div class="time">{{activity.activityTime}}</div>
                                <div class="adds">{{activity.location}}</div>
                                <div class="price">¥{{activity.price}}</div>
                            </div>
                            <div class="label-box">
                                <div v-for="condition in activity.condition" :key="condition">{{condition | filter_condition}}</div>
                            </div>
                            <div class="btn-box">
                                <div v-if="!activity.finished" class="btn window-btn" @click="handleSignup">参与报名</div>
                                <div v-else class="btn no">已结束</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- module-1 -->
            <div class="module-2">
                <div class="mainBox">
                    <div class="tips">
                        <h2>简介</h2>
                        <div class="page-info" v-html="activity.wxts"></div>
                    </div>
                    <div class="con">
                        <h2>介绍</h2>
                        <div class="page-info" v-html="activity.content"></div>
                    </div>
                    <div class="notice">
                        <h1>报名信息</h1>
                        <h2>活动地址</h2>
                        <div class="page-info" v-html="activity.xggz"></div>
						<h2>活动费用</h2>
                        <div class="page-info" v-html="activity.smzgz"></div>
                        <h2>入场须知</h2>
                        <div class="page-info" v-html="activity.rcxz"></div>
						<h2>退换规则</h2>
                        <div class="page-info" v-html="activity.thgz"></div>
                    </div>
                </div>
            </div>
            <!-- module-2 -->
        </div>
        <!-- mobile-wrap -->

        <!-- 购买 -->
        <div class="window-buy" style="display: none">
            <div class="window-mask"></div>
            <div class="window-con">
                <div class="close" @click="handleClose">
                    <span class="iconfont iconclose"></span>
                </div>
                <div class="title"><b>场次</b><span></span></div>
                <div class="specs">
					<div 
						class="specs-list" 
						v-for="item in activity.specList" 
						:key="item.id"
					>
						<div class="radio-box" v-if="item.inventory > 0">
							<input 
								type="radio" 
								name="radio" 
								:id="`radio-${item.id}`" 
								required="" 
								:value="item"
								v-model="selectedSpec"
							/>
							<label :for="`radio-${item.id}`">
								<span>{{item.name}}</span>
								<span>¥{{item.price}}</span>
							</label>
						</div>
					</div>
                </div>
                <div class="price">
                    <div class="total">合计 ¥{{selectedSpec.price}}</div>
                    <div class="buy-btn">
                        <button class="btn" @click="handleConfirm">确认</button>
                    </div>
                </div>
            </div>
        </div>
        <bottom />
        <side />
		<el-dialog title="确认信息" :visible="showOrderDialog" :before-close="handleCancel" :close-on-click-modal="false"> 
			<el-form ref="order" :model="order">
				<el-form-item>
					<el-col :span="12">
						<el-form-item label="姓名" >
							<el-input
								auto-complete="off"
								placeholder="请输入姓名"
								style="width: 95%"
								v-model="order.user.name"
							/>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="性别">
							<el-select v-model="order.user.gender" placeholder="请选择" style="width: 100%">
								<el-option label="男" value="男" />
								<el-option label="女" value="女" />
							</el-select>
						</el-form-item>
					</el-col>
				</el-form-item>
				<el-form-item label="身份证号">
					<el-input
						auto-complete="off"
						placeholder="身份证号"
						v-model="order.user.idCardNo"
					/>
				</el-form-item>
				<el-form-item label="省 / 市 / 区县 / 街道">
					<select-address @fromComponentAddress="handleSetLevel4Address" />
				</el-form-item>
				<el-form-item label="详细地址">
					<el-input
						auto-complete="off"
						placeholder="请输入详细地址"
						v-model="order.user.detailAddress"
					/>
				</el-form-item>
				<el-form-item label="手机号码">
					<el-input
						auto-complete="off"
						placeholder="请输入手机号码"
						v-model="order.user.phoneNumbers"
					/>
				</el-form-item>
				<el-form-item>
					<el-col :span="24">
						<el-input
						auto-complete="off"
						placeholder="请输入图形验证码"
						style="width: 100%"
						v-model="order.verifyCode"
					/>
					</el-col>
					<el-col :span="6" class="verifyCode">
						<el-form-item>
							<div @click="refreshCode">
								<identify :identifyCode="identifyCode"/>
							</div>
						</el-form-item>
					</el-col>
				</el-form-item>
				<el-form-item>
					<el-col :span="24">
						<el-form-item>
							<el-input
								auto-complete="off"
								placeholder="请输入手机验证码"
								v-model="order.sms.verifyCode"
							/>
						</el-form-item>
					</el-col>
					<el-col :span="6" class="verifyCode">
						<el-form-item>
							<el-button type="info" @click="sendSms" :disabled="sendButtonText !== '获取验证码'">{{sendButtonText}}</el-button>
						</el-form-item>
					</el-col>
				</el-form-item>
				<el-form-item label="备注">
					<el-input
						auto-complete="off"
						placeholder="备注"
						v-model="order.remark"
					/>
				</el-form-item>
				<el-form-item>
					*本手机号将作为您以后登录本网站的账号
				</el-form-item>
				<el-form-item>
					<el-button type="success" @click="handlePrepay('MWEB')" class="mobile">支付</el-button>
					<el-button type="success" @click="handlePrepay('NATIVE')" class="pc">支付</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog title="微信扫码支付" :visible="showQrCodeDialog" width="450px" :before-close="handleCloseQrCode" :close-on-click-modal="false"> 
			<vue-qr :text="codeUrl" :size="400"></vue-qr>
		</el-dialog>
    </div>
</template>

<script>
import top from "./components/top.vue";
import bottom from "./components/bottom.vue";
import focusPic from "./components/focusPic.vue";
import side from "./components/side.vue";
import selectAddress from "./components/SelectAddress.vue";
import identify from './components/identify.vue';
import vueQr from 'vue-qr';
import { mapGetters } from "vuex";
import { wxShare } from "@/utils/share.js"
export default {
    page: {
        title: '活动详情',
    },
	filters: {
		filter_condition(value) {
			let ret = ''
			switch(value) {
				case 'RealName':
					ret = '实名制'
					break;
				case 'E-Ticket':
					ret = '电子票'
					break;
				case 'NoRefund':
					ret = '不支持退'
					break;
				case 'NoPutOff':
					ret = '不支持延期'
					break;
				case 'WithIDCard':
					ret = '携带身份证入场'
					break;
				case 'VIP':
					ret = 'vip活动'
					break;	
				case 'NAT48':
					ret = '48小时核酸证明'
					break;
			}

			return ret
		}
	},
    data() {
        return {
			formRules: {

			},
            activity: {
				condition: [],
				cover: {
					absolutePath: ''
				}
			},
			selectedSpec: {
				price: 0
			},
			showOrderDialog: false,
			showQrCodeDialog: false,
			order: {
				activity: {
					id: '',
					name: '',
					specList: [
						{
							id: ''
						}	
					]
				},
				user: {
					name: '', //姓名
					gender: '男 ', //性别
					idCardNo: '', //身份证号
					level4Address: '', //四级地址，省/市/区县/街道
					detailAddress: '', //详细地址
					address: '',
					phoneNumbers: '', //联系电话
				},
				sms: {
					verifyCode: '' //电话验证码
				},
				verifyCode: '', //图形验证码
				remark: '', //备注
				tradeType: '' //下单方式
			},
			identifyCodes: '1234567890abcdefjhijklinopqrsduvwxyz',//随机串内容
   			identifyCode: '',
			count: 0,
			sendButtonText: '获取验证码',
			codeUrl: ''
        };
    },
	computed: {
		...mapGetters(["grade"])
	},
    components: {
        top,
        bottom,
        focusPic,
        side,
		selectAddress,
		identify,
		vueQr
    },
    methods: {
		handleWxShare(activity) {
			let url = window.location.href

			this.$api.wechat.mpwechat.getJsapiSignature(url)
			.then(response => {
				if(response.code === '0') {

					let browser = window.navigator.userAgent.toLowerCase();	

					// 非微信浏览器不执行分享代码
					if (browser.match(/MicroMessenger/i) != 'micromessenger') {
						return;
					} 	

					//微信加签
                    let obj = {
                        appId: response.data.appId,
                        nonceStr: response.data.nonceStr,
                        signature: response.data.signature,
                        timestamp: response.data.timestamp,
                        jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData']
                    };

                    // var shareWxLink = encodeURIComponent(url);加密
                    let shareData = {
                        title: activity.name, // 分享标题
                        desc: activity.wxts,
                        link: response.data.url,
                        imgUrl: activity.cover.absolutePath // 分享图标
                    };

					//引用
                    wxShare(obj, shareData);
				}
				else {
					this.$message.info(response.message)
				}
			})
		},
        getSingle() {
            this.$api.home.activity.getSingle(this.$route.params.id)
			.then((response) => {
				if(response.code === '0') {
					this.activity = response.data;
					this.order.activity.id = this.activity.id
					this.order.activity.name = this.activity.name

					this.handleWxShare(this.activity)
				}
				else {
					this.$message.error(response.message)
				}
            });
        },
		handleSignup() {
			//判断活动是否是vip活动
			if(this.activity.condition.indexOf('VIP') >= 0) {
				//该活动是VIP活动
				if(typeof this.grade === 'undefined' || this.grade === '') {
					this.$message.info('该活动仅限VIP参与')
					return
				}
				else {
					if(this.grade !== 'VIP') {
						this.$message.info('该活动仅限VIP参与')
						return
					}
				}
			}

			$(".window-buy").fadeIn("fast");
			$(".window-buy").addClass("open");
		},
		handleClose() {
			$(".window-buy").fadeOut("fast");
			$(".window-buy").removeClass("open");
		},
		handleConfirm() {
			if(typeof this.selectedSpec.id!== 'undefined') {
				this.order.activity.specList[0] = this.selectedSpec;

				this.handleClose();
				this.showOrderDialog = true
			}
			else {
				this.$message.info('请选择您要购买的场次')
			}
		},
		// 重置验证码
		refreshCode () {
			this.identifyCode = ''
			this.makeCode(this.identifyCodes, 4)
		},
		makeCode (o, l) {
			for (let i = 0; i < l; i++) {
				this.identifyCode += this.identifyCodes[this.randomNum(0, this.identifyCodes.length)]
			}
		},
		randomNum (min, max) {
			return Math.floor(Math.random() * (max - min) + min)
		},
		sendSms() {
			if(this.order.user.phoneNumbers === '') {
				this.$message.info('手机号码不能为空')
				return
			}

			if(this.order.verifyCode === '') {
				this.$message.info('请输入图形验证码')
				return false
			}
			else
			{
				if(this.order.verifyCode !== this.identifyCode)
				{
					this.$message.info('图形验证码输入不正确')
					return false
				}
			}

			if(this.sendButtonText !== '获取验证码') {
				return
			}

			this.$api.aliyun.sms.sendSms(this.order.user.phoneNumbers)
			.then(response => {
				if(response.code === '0') {
					this.count = 60
					this.sendButtonText = `倒计时：${this.count}秒`

					let times = setInterval(() => {
						this.count--
						this.sendButtonText = `倒计时：${this.count}秒`
						if(this.count <= 0) {
							this.sendButtonText = '获取验证码'
							clearInterval(times);
						}
					}, 1000)
				}
				else {
					this.$message.error(response.message)
				}
			})
		},
		handleSetLevel4Address(level4Address) {
			this.order.user.level4Address = level4Address.join("")
		},
		handleValidateOrder() {
			if(this.order.user.name === '') {
				this.$message.info('姓名不能为空')
				return false
			}
			if(this.order.user.gender === '') {
				this.$message.info('性别不能为空')
				return false
			}
			if(this.order.user.idCardNo === '') {
				this.$message.info('身份证号不能为空')
				return false
			}
			if(this.order.user.level4Address === '') {
				this.$message.info('请选择您所在的位置（省/市/区县/街道）')
				return false
			}
			if(this.order.user.detailAddress === '') {
				this.$message.info('详细地址不能为空')
				return false
			}
			//具体地址为level4拼接详细地址
			this.order.user.address = `${this.order.user.level4Address}${this.order.user.detailAddress}`

			if(this.order.user.phoneNumbers === '') {
				this.$message.info('手机号码不能为空')
				return false
			}
			if(this.order.verifyCode === '') {
				this.$message.info('请输入图形验证码')
				return false
			}
			else
			{
				if(this.order.verifyCode !== this.identifyCode)
				{
					this.$message.info('图形验证码输入不正确')
					return false
				}
			}
			if(this.order.sms.verifyCode === '') {
				this.$message.info('请输入手机验证码')
				return false
			}
			return true
		},
		handlePrepay(tradeType) {
			if(!this.handleValidateOrder()) {
				return
			}

			this.order.tradeType = tradeType

			this.$api.home.activity.prepay(this.order)
			.then(response => {
				if(response.code === '0') {

					// 微信扫码付	
					if(tradeType === 'NATIVE') {
						this.showOrderDialog = false;
						this.codeUrl = response.data;
						this.showQrCodeDialog = true;
					}
					// h5方式外部链接支付
					else if(tradeType === 'MWEB') {
						this.showOrderDialog = false;
						location.href = response.data;
					}
				}	
				else {
					this.$message.error(response.message)
				}
			})
		},
		handleCancel() {
			this.showOrderDialog = false
		},
		handleCloseQrCode() {
			this.showQrCodeDialog = false
		}
    },
    mounted() {
        this.getSingle()
		this.identifyCode = ''
  		this.makeCode(this.identifyCodes, 4)
    },
};
</script>

<style src="../style/css/activity.css" scoped></style>
<style scoped>
.verifyCode {
	position: absolute;
	top: 4px;
	right: 6px;
}
.con .page-info p img {
	width: 100px;
}
</style>